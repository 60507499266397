import React, { memo, useEffect, useState } from "react";
import Card from "../Card/Card";
import io from "../../actions/socket";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { rootApi } from "../../axiosConfig";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import { useHistory } from "react-router-dom";
import { getUser } from "../../actions/actions";

const DairyCard = memo(
  ({ dairy, users, editHandlerEvent, deleteHandlerEvent }) => {
    const { t } = useTranslation();
    const userRole = getUser();
    const [countAlert, setCountAlert] = useState(false);
    const { setBreadcrumbDairyFarm } = useBreadcrumbContext();
    const history = useHistory();
    useEffect(() => {
      rootApi
        .get(
          `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
        )
        .then((data) => setCountAlert(data.data.length > 0 ? true : false))
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("alert_history_error")}
            />
          );
        });
      io.socket.on(`alerthistory:manual:${dairy.id}`, (data1) => {
        if (data1["isResolved"] === false) {
          setCountAlert(true);
        } else {
          rootApi
            .get(
              `alerthistories?where={"and":[{"alert":null},{"dairy":"${dairy.id}"},{"isResolved":false},{"configKey":{"!=":"MANUAL_ALERTS"}}]}&sort=updatedAt DESC&limit=1000`
            )
            .then((data) => {
              setCountAlert(data.data.length > 0 ? true : false);
            })
            .catch((err) => {
              toast.dark(
                <Toaster
                  icon="error"
                  message={err?.response?.message ?? t("alert_history_error")}
                />
              );
            });
        }
      });
    }, []);

    /** Display tank count in dairy card */
    const tankCount = dairy?.tanks
      ? dairy?.tanks.length == 1
        ? dairy?.tanks.length + " Tank"
        : dairy?.tanks.length == 0
          ? "--"
          : dairy?.tanks.length + " Tanks"
      : "";
    return (
      <Card
        key={"dairyCard" + dairy.id}
        title={dairy.name}
        subtitle={dairy.city + ", " + dairy.state}
        rightIcon={true}
        onClick={() => {
          let url = `/regions/${dairy?.createdBy?.region}/dealers/${dairy.createdBy.id}/dairies/${dairy.id}/tank`;
          if (
            userRole.role === "Technician" ||
            userRole.role === "DairyOperator" ||
            userRole.role === "DairyAdmin" ||
            userRole.role === "Sanitarian" ||
            userRole.role === "MilkHauler"
          ) {
            url = `/regions/dealers/${userRole.role === "Technician" ? users.createdBy : users.id}/dairies/${dairy.id}/tank`;
          }
          if (!dairy?.createdBy?.region) {
            url = `/regions/dealers/${users.id}/dairies/${dairy.id}/tank`;
          }
          history.push(url);
          setBreadcrumbDairyFarm(dairy.name);
        }}
        deleteHandler={() => {
          deleteHandlerEvent(dairy.id);
        }}
        editHandler={() => {
          editHandlerEvent(dairy.id);
        }}
        item={dairy}
        isMonitoring={
          userRole.role === "DairyOperator" ||
            userRole.role === "Sanitarian" ||
            userRole.role === "MilkHauler" ||
            userRole.role === "DairyAdmin"
            ? ""
            : dairy.monitors.findIndex((el) => el.id === users.id) > -1
              ? 1
              : 0
        }
        isAlert={countAlert}
      >
        {tankCount}
      </Card>
    );
  }
);

DairyCard.propTypes = {
  dairy: PropTypes.any,
  users: PropTypes.any,
};

export default DairyCard;

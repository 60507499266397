import React, { useEffect, useRef, useState } from "react";
import PropTypes, { array } from "prop-types";
// import Dygraph from "dygraphs";
import Dygraph from "dygraphs/index.es5";
// eslint-disable-next-line
import Plugins from "dygraphs/src/extras/synchronizer";
// eslint-disable-next-line
import Hairlines from "dygraphs/src/extras/hairlines";
import Crosshair from "./CrossHair";
import moment from "moment-timezone";
import "bootstrap-daterangepicker/daterangepicker.css";
import styled from "styled-components";
import { Row, Col, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import CustomCheckbox from "../CustomCheckbox";
import tempIcon from "../../assets/images/thermometer.svg";
import volumeIcon from "../../assets/images/Volume.svg";
import whiteStandbyIcon from "../../assets/images/standby-white.png";
import whiteFanIcon from "../../assets/images/fan-white.png";
import whiteSetupIcon from "../../assets/images/setup-white.png";
import coolingIcon from "../../assets/images/tooltip-snowflake.png";
import washingIcon from "../../assets/images/washing.png";
import diagnosticsIcon from "../../assets/images/Diagnostics.png";
import $ from "jquery";
import Sidebar from "../AnnotationSidebar/AnnotationSidebar";
import { useParams } from "react-router-dom";
import {
  postManualAnnotation,
  DeleteManualAnnotation,
  getUser,
} from "../../actions/actions";
import Toaster from "../Toaster";
import { toast } from "react-toastify";
import { ReactComponent as SystemIcon } from "../../assets/images/systemAlert.svg";
import { ReactComponent as ManualAlertIcon } from "../../assets/images/manualAlert.svg";
import Delete from "../../assets/images/delete.svg";
import PromptModel from "../PromptModel";

import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
} from "../../helpers";

$(document).on("mouseover", ".dygraph-hairline", function () {
  $(".untitled").addClass("d-none");
  $(this).next("div").find(".untitled").removeClass("d-none");
});

const GraphWrapper = styled.div`
  margin-top: 20px;
  padding: 20px 10px 40px 10px;
  border-radius: 10px;
  border: solid 2px #d6e6ff;
`;

const CheckboxText = styled.span`
  margin-left: 5px;
  margin-right: 20px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`;

const CheckboxContainer = styled(Col)`
  align-self: center;
  padding-left: 4%;
`;

const Graph = (props) => {
  const {
    data,
    alerts,
    showAnnotation,
    sensors,
    graphId,
    t,
    washData,
    manualAnnotation,
    getAlerts,
    dropdownTime,
    isResponsiveMode,
  } = props;

  const getTankTooltipData = (temp, vol, state) => {
    const tooltipData = {
      tankTemp: null,
      tankVolume: null,
      tankState: null,
    };

    // Set Tank Temperature for graph tooltip
    if(temp != null && !Number.isNaN(temp)) {
      const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
      tooltipData.tankTemp = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
    }

    // Set Tank Volume for graph tooltip
    if (vol != null && !Number.isNaN(vol)) {
      const { volume, volumeUnit } = getVolumeAsPerSelectedUnitSystem(Number(vol));
      tooltipData.tankVolume = (!Number.isNaN(volume) && volumeUnit) ? `${volume} ${volumeUnit}` : null;
    }

    // Set Tank State for graph tooltip
    if (state != null && !Number.isNaN(state)) {
      tooltipData.tankState = Number(state);
    }

    return tooltipData;
  };

  const tooltip = (dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList = []) => {
    const date = new Date(dygraphLegendFormatter.x);
    let tankTemp = null;
    let tankVolume = null;
    let tankState = null;

    if(graphId === 'hpf') {
      const tooltipData = getTankTooltipData(graphValuesList[1], graphValuesList[5], graphValuesList[6]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;
    }
    else if (graphId === 'evc0') {
      const tooltipData = getTankTooltipData(graphValuesList[5], graphValuesList[6], graphValuesList[7]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;
    }
    else if (graphId === 'evc1' || graphId === 'evc2') {
      const tooltipData = getTankTooltipData(graphValuesList[2], graphValuesList[3], graphValuesList[4]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;
    }

    const milkPipelineTemp =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";
    const tempSensor3 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";
    const tempSensor4 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";
    const LSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const SST =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";

    const HSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";

    const SCT =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";

    const subCoolTemp =
      graphId === "evc1"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const evcTemp =
      graphId === "evc2"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    return `<div class="graph-tooltip justify-content-center w-100">
      <div class="panel-box text-white">
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-sm-4 right-border my-2 p-0">
            <img class="mb-1" src=${tempIcon} alt="" title=""/>
            <div>${tankTemp}</div>
          </div>
          <div class="col-sm-4 right-border my-2 p-0">
            <img class="mb-1" src=${volumeIcon} alt="" title=""/>
            <div>${tankVolume}</div>
          </div>
          ${ tankState != null && tankState >= 0 && tankState <= 1000
            ? `<div class="col-md-4 col-sm-4 my-2 p-0">
                <img class="img mb-1" src=${whiteStandbyIcon} alt="Standby" title="" />
                <div>${t("STANDBY")}</div>
              </div>`
            : ''
          }
          ${ tankState === 1001
            ? `<div class="col-md-4 col-sm-4 my-2 p-0">
                <img class="img mb-1" src=${whiteFanIcon} alt="Fan" title="" />
                <div>${t("AGITATE")}</div>
              </div>`
            : ''
          }
          ${ tankState === 1002 || tankState === 1004
            ? `<div class="col-md-4 col-sm-4 my-2 p-0 mode-font">
                <img class="img mb-1" src=${whiteSetupIcon} alt="Setup" title="" />
                <div>${t("SETTINGS")}</div>
              </div>`
            : ''
          }
          ${ tankState === 1003
            ? `<div class="col-md-4 col-sm-4 my-2 p-0 mode-font">
                <img class="img mb-1" src=${diagnosticsIcon} alt="Setup" title="" />
                <div>${t("DIAGNOSTIC")}</div>
              </div>`
            : ''
          }
          ${ tankState >= 2000 && tankState <= 2006
            ? `<div class="col-md-4 col-sm-4 my-2 p-0">
                <img class="img mb-1" src=${coolingIcon} alt="Cooling" title="" />
                <div>${t("COOL")}</div>
              </div>`
            : ''
          }
          ${ tankState >= 3000 && tankState <= 4000
            ? `<div class="col-md-4 col-sm-4 my-2 p-0">
                <img class="img mb-1" src=${washingIcon} alt="Washing" title="" />
                <div>${t("WASH")}</div>
              </div>`
            : ''
          }
        </div>
        <div class="horizontal-border"></div>
      </div>

      ${graphId === "hpf"
        ? `<div class="row pb-2 px-3">

        <div class="col-md-1 col-sm-1">
          <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left pb-1">${t(
          "milk_pipeline_temperature"
        )}</div>
        <div class="col-md-3 col-sm-5 text-right pb-1">${milkPipelineTemp}</div>
        <div class="col-md-1 col-sm-1 py-1">
        <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left py-1">${t(
          "temperature_sensor_3"
        )}</div>
        <div class="col-md-3 col-sm-5 text-right py-1">${tempSensor3}</div>
        <div class="col-md-1 col-sm-1 py-1">
        <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left pt-1">${t(
          "temperature_sensor_4"
        )}</div>
        <div class="col-md-3 col-sm-5 text-right pt-1">${tempSensor4}</div>
      </div>`
        : ""
      }
      ${graphId === "evc0"
        ? `<div class="row py-2 px-3">
        <div class="col-md-1 col-sm-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left pb-1">${t("lsp")}</div>
        <div class="col-md-3 col-sm-5 text-left pb-1">${LSP}</div>
        <div class="col-md-1 col-sm-1 py-1">
        <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left py-1">${t("sst")}</div>
        <div class="col-md-3 col-sm-5 text-left py-1">${SST}</div>
        <div class="col-md-1 col-sm-1 py-1">
        <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left py-1">${t("hsp")}</div>
        <div class="col-md-3 col-sm-5 text-left py-1">${HSP}</div>
        <div class="col-md-1 col-sm-1 pt-1">
        <div class="legend-tooltip rounded-circle pt-1" style="background-color:${dygraphLegendFormatter.series[3].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left pt-1">${t("sct")}</div>
        <div class="col-md-3 col-sm-5 text-left pt-1">${SCT}</div>
      </div>`
        : ""
      }
      ${graphId === "evc1"
        ? `
        <div class="row py-1 px-3">
        <div class="col-md-1 col-sm-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left">${t(
          "subcool_temperature"
        )}</div>
        <div class="col-md-3 col-sm-5 text-right">${subCoolTemp}</div>
        </div>`
        : ""
      }
      ${graphId === "evc2"
        ? `
      <div class="row py-1 px-3">
        <div class="col-md-1 col-sm-1">
          <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color
        }"></div>
        </div>
        <div class="col-md-7 col-sm-5 text-left">${t("evc_temperature")}</div>
        <div class="col-md-3 col-sm-5 text-right">${evcTemp}</div>
      </div>`
        : ""
      }
      <div class="w-100 text-center pb-2 mt-2 font-weight-bold">
                  <span>
                  ${dropdownTime === undefined
        ? moment(date).format("MM/DD/YY hh:mm A")
        : moment
          .tz(new Date(date), dropdownTime)
          .format("MM/DD/YY hh:mm A")
      }
                  </span>
              </div>
        </div>
        </div>
  </div>`;
  };
  const sensorDisplayName = {
    HPF_INPUT_REG_TEMP1: {
      name: t("tank_temperature"),
    },
    HPF_INPUT_REG_TEMP2: {
      name: t("milk_pipeline_temperature"),
    },
    HPF_INPUT_REG_TEMP3: {
      name: t("temperature_sensor_3"),
    },
    HPF_INPUT_REG_TEMP4: {
      name: t("temperature_sensor_4"),
    },
    HPF_INPUT_REG_VOLUME_4TO20MA_READING: {
      name: t("tank_volume"),
    },
    HPF_INPUT_REG_MAIN_STATE: {
      name: "Tank State",
      hideCheckBox: true,
    },
    EVC_INPUT_REG_TEMP_1: {
      name: t("evc_temperature"),
    },
    EVC_INPUT_REG_SUBCOOL_TEMP: {
      name: t("subcool_temperature"),
    },
    EVC_INPUT_REG_LOW_SIDE_PRESSURE: {
      name: `${t("lsp")}`,
    },
    EVC_INPUT_REG_HIGH_SIDE_PRESSURE: {
      name: `${t("hsp")}`,
    },
    EVC_INPUT_REG_HI_SAT_TEMP: {
      name: `${t("sct")}`,
    },
    EVC_INPUT_REG_LO_SAT_TEMP: {
      name: `${t("sst")}`,
    },
  };


  if (graphId.includes('evc')) {
    sensorDisplayName['HPF_INPUT_REG_TEMP1'].hideCheckBox = true;
    sensorDisplayName['HPF_INPUT_REG_VOLUME_4TO20MA_READING'].hideCheckBox = true;
  }

  const checkboxColor = {
    [t("tank_temperature")]: {
      color: "#3ca1ff",
    },
    [t("milk_pipeline_temperature")]: {
      color: "#7ae43f",
    },
    [t("temperature_sensor_3")]: {
      color: "#b068d6",
    },
    [t("temperature_sensor_4")]: {
      color: "#efc53a",
    },
    [t("tank_volume")]: {
      color: "#ff5c5c",
      axis: "y2",
    },
    [t("evc_temperature")]: {
      color: "#e84e4a",
    },
    [t("subcool_temperature")]: {
      color: "#f3c72f",
    },
    [t("lsp")]: {
      color: "#0000ff",
    },
    [t("hsp")]: {
      color: "#ff0000",
    },
    [t("sct")]: {
      color: "#b068d6",
      axis: "y2",
    },
    [t("sst")]: {
      color: "#efc53a",
      axis: "y2",
    },
  };

  const TempType = [
    t("tank_temperature"),
    t("milk_pipeline_temperature"),
    t("evc_temperature"),
    t("subcool_temperature"),
  ];

  const [checkboxStatus, setCheckboxStatus] = useState(
    sensors.map((el) => true)
  );
  const [showDrawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("");
  const [dygraph, setDygraph] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState();
  const { dairyID, tankID } = useParams();
  const [open, setOpen] = useState(false);
  const user = getUser();
  const configId = "MANUAL_ALERTS";

  /** Display line in particular point */
  const pointClick = async (event, p) => {
    let createdAt = p;
    if (dropdownTime !== undefined) {
      createdAt = moment.tz(new Date(p), dropdownTime).valueOf();
    }
    if (manualAnnotation) {
      try {
        const data = {
          tank: tankID,
          dairy: dairyID,
          configDisplay: "Untitled",
          alertText: "--",
          createdAt: createdAt,
          createdBy: user.id,
        };
        const res = await postManualAnnotation(data);
        if (res.status === 200) {
          getAlerts();
        }
      } catch (err) {
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
  };

  const replaceSensorWithDisplayName = (sensorsList, ignoreList) => {
    // Ignoring sensors that are passed in the 'ignoreList'
    if (Array.isArray(ignoreList) && ignoreList.length) {
      sensorsList = sensorsList.filter(sensor => !ignoreList.includes(sensor));
    }
    return sensorsList.map((sensor) => sensorDisplayName[sensor].name);
  }

  const handleCheckboxes = (index) => {
    if (checkboxStatus[index]) {
      checkboxStatus[index] = false;
      dygraph.setVisibility(index, false);
    } else {
      checkboxStatus[index] = true;
      dygraph.setVisibility(index, true);
    }
    setCheckboxStatus([...checkboxStatus]);
  };

  const renderGraphData = (alert) => {
    const manualItems = alert?.filter((i) => i.configKey === configId);
    let hairlines = new Dygraph.Plugins.Hairlines({
      divFiller: function (div, data) {
        const index = alert.findIndex((el) => {
          return el.createdAt === data.hairline.xval;
        });
        const isManual = manualItems.findIndex(
          (i) => i.createdAt === data.hairline.xval
        );
        $(".hairline-legend", div).html(
          `<p class="d-none untitled" style="overflow: hidden;cursor: default; white-space: nowrap;text-overflow: ellipsis;" id=${index}>${alert[index]?.configDisplay}</p>`
        );
        $(div).data({ xval: data.hairline.xval });
        div.id = "hairline-" + index;
        if (isManual > -1) {
          div.className = div.className + " manual-item";
        } else {
          div.className = div.className + " system-item";
        }
        const child = div.childNodes[0];
        child.id = index; // see .hover() below.
        child.style.display = "flex";
        child.style.flex = "1";
        child.style.justifyContent = "center";
        child.style.padding = "1rem 10px 0px 10px";
      },
    });
    // }

    /** Display HairLine in dygraph */
    let plugins = [hairlines];
    if (manualAnnotation) {
      let crossLine = new Dygraph.Plugins.Crosshair({
        direction: "vertical",
      });
      plugins.push(crossLine);
    }

    if (data) {
      const graphOptions = {
        height: 450,
        axisLineWidth: 0.00000000001,
        plugins: plugins,
        visibility: sensors.map((sensor) => !sensorDisplayName[sensor]?.hideCheckBox),
        legend: "follow",
        legendFormatter(dygraphLegendFormatter) {
          // Get all the sensors' data at a given time 'x'.
          let graphValuesList = dygraphLegendFormatter.dygraph.rawData_.find((d) => d[0] === dygraphLegendFormatter.x);

          // Show tooltip only if there is some data to show other than the timestamp.
          if (graphValuesList && graphValuesList[1]) {
            return tooltip(dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList);
          }

          return null;
        },
        clickCallback: pointClick,
        includeZero: true,
        series: checkboxColor,
        axes: {
          x: {
            drawAxis: true,
            drawGrid: false,
            valueFormatter(val) {
              if (dropdownTime === undefined) {
                const value = moment(val).format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              } else {
                const value = moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              }
            },
            axisLabelFormatter(val) {
              if (dropdownTime === undefined) {
                return moment(val).format("MM/DD/YY hh:mm:ss A");
              } else {
                return moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YY hh:mm:ss A");
              }
            },
          },
          y: {
            drawAxis: true,
            gridLinePattern: [4, 4],
            axisLabelWidth: 63, // width of label on axis y2
            valueFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(
                  Number(val),
                  sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP")
                );
                unit = tempUnit;
                value = temperature;
              }
              return `${value}&nbsp;${unit}`;
            },
            axisLabelFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              }

              return `${parseInt(value, 10)}&nbsp;${unit}`;
            },
          },
          y2: {
            drawAxis: true,
            valueFormatter(val) {
              const sensorArr = replaceSensorWithDisplayName(sensors);

              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(
                  Number(val),
                  sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP")
                );
                unit = tempUnit;
                value = temperature;
              } else {
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              }

              return `${parseInt(value, 10)} ${unit}`;
            },
            axisLabelFormatter(val) {
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              } else {
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              }

              return `${parseInt(value, 10)} ${unit}`;
            },
            includeZero: true, // start y2 axis from zero
            axisLabelWidth: 40, // width of label on axis y2
          },
        },
        underlayCallback(canvas, area, g) {
          const canvasData = canvas;
          if (graphId === 'hpf' && washData !== undefined) {
            washData.forEach((value) => {
              canvasData.fillStyle = "#FDEFC0";
              const bottomLeft = g.toDomCoords(value.start, -20)[0];
              const topRight = g.toDomCoords(value.end, +20)[0];
              canvasData.fillRect(
                bottomLeft,
                area.y,
                topRight - bottomLeft,
                area.h
              );
            });
          }
        },
        drawPoints: false,
        animatedZooms: false,
        rangeSelectorHeight: 40,
        labels: ["Date"].concat(replaceSensorWithDisplayName(sensors)),
      };

      if (isResponsiveMode) {
        // Disable vertical scrolling in graph in Responsive(Mobile) view
        graphOptions.interactionModel = {
          touchstart: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchstart(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
          touchmove: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchmove(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
        };
      }

      const graph = new Dygraph(document.getElementById(`graph${graphId}`), data, graphOptions);

      setCheckboxStatus((prev) => prev.map((el) => true));
      setDygraph(graph);
    }
    if (alerts && showAnnotation) {
      const hairlineValue = alerts?.map((el) => {
        return { xval: el.createdAt, interpolated: false };
      });
      const manualItems = alerts?.filter((i) => i.configKey !== configId);

      hairlines.set(hairlineValue);
      const totalHairline = document.getElementsByClassName("hairline-info");
      for (let i = 0; i < totalHairline.length; i++) {
        totalHairline[i].addEventListener("click", (e) => {
          setSelectedAlert(alerts[e.target.id]);
          setDrawer(true);
        });
        totalHairline[i].previousElementSibling.addEventListener(
          "mouseout",
          (e) => {
            $(".hairline-info").find(".untitled").addClass("d-none");
            let element = $(e.target).closest("div").parent().next();
            element.find(".untitled").removeClass("d-none");
          }
        );

        let dygraphHairline = $(totalHairline[i]).prev();
        if (
          dygraphHairline.length > 0 &&
          totalHairline[i].id !== "hairline-template"
        ) {
          var id = totalHairline[i].id;
          dygraphHairline[0].id = id;
          totalHairline[i].id = id;
          let systemId = id.split("hairline-")[1];
          let manualId = id.split("hairline-")[1];
          const isManual = manualItems.findIndex(
            (i) => i.createdAt === alerts[manualId].createdAt
          );

          const systemItem = manualItems.findIndex(
            (i) =>
              i.createdAt === alerts[systemId].createdAt &&
              i.isResolved === false
          );
          if (isManual > -1) {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " system-item";
            if (systemItem > -1) {
              dygraphHairline[0].className =
                dygraphHairline[0].className + " anim-glow";
            } else {
              dygraphHairline[0].className = dygraphHairline[0].className;
            }
          } else {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " manual-item";
          }
        }
      }
      const totalDygraphHairline = document.getElementsByClassName(
        "dygraph-hairline"
      );
      for (let i = 0; i < totalDygraphHairline.length; i++) {
        if (totalDygraphHairline[i].id !== $(".graph").attr("id")) {
          totalDygraphHairline[i].addEventListener("click", (e) => {
            let id = totalDygraphHairline[i].id.split("-")[1];
            setSelectedAlert(alerts[id]);
            // setTitle(alerts[id].configDisplay);
            setDrawer(true);
          });
        }
      }
    }
  };

  /** Delete Manual annotation functionality */
  const deleteAnnotation = (selectedAlert) => {
    DeleteManualAnnotation(selectedAlert)
      .then((response) => {
        if (response.status === 200) {
          setOpen(false);
          setDrawer(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("manual_annotation_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          getAlerts();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response ?? t("something_went_wrong")}
          />
        );
      });
  };

  //Render the graph Data when graph component loads for the first time and when data or annotation value change
  useEffect(() => {
    renderGraphData(alerts);
  }, [data, showAnnotation, alerts]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [manualAnnotation, dropdownTime]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [showDrawer, alerts]);

  const checkboxItems = (graphId.includes('evc'))
    ? replaceSensorWithDisplayName(sensors, [
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
    ])
    : replaceSensorWithDisplayName(sensors, ['HPF_INPUT_REG_MAIN_STATE']);

  return (
    <>
      <GraphWrapper>
        <Row className="mb-5">
          <CheckboxContainer lg={12}>
            {checkboxItems.map((item, index) => {
              return (
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                <>
                  <label className="mb-1 mt-1" key={index}>
                    <CustomCheckbox
                      color={checkboxColor[item]?.color}
                      onChange={() => handleCheckboxes(index)}
                      checked={checkboxStatus[index]}
                    />
                  </label>
                  <CheckboxText>{t(item)}</CheckboxText>
                </>
              );
            })}
          </CheckboxContainer>
        </Row>
        <div
          id={`graph${graphId}`}
          className="graph"
          style={{
            cursor: "pointer",
          }}
        />
        <div
          id="hairline-template"
          className="hairline-info text-primary"
          style={{ display: "none" }}
        >
          <div className="hairline-legend"></div>
        </div>
      </GraphWrapper>
      {showDrawer && (
        <Sidebar
          onClose={() => setDrawer(false)}
          visible={showDrawer}
          title={
            selectedAlert.configKey === configId ? (
              <div className="d-flex align-items-center mb-0">
                <div className="text-primary">
                  <ManualAlertIcon className="mr-2 align-text-bottom" />
                  {t("manual_notes")}
                </div>
                <div
                  className="ml-auto mr-3 delete d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Image src={Delete} />
                </div>
              </div>
            ) : (
                <div className="text-danger my-1">
                  <SystemIcon className="mr-2 align-text-bottom" />
                  {t("system_alert")}
                </div>
              )
          }
          key={selectedAlert?.id}
          alert={selectedAlert}
          getAlerts={getAlerts}
          dropdownTime={dropdownTime}
          configId={configId}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_annotation_msg")}
        className="text-black mt-3"
        callback={() => deleteAnnotation(selectedAlert.id)}
      />
    </>
  );
};

export default withTranslation()(Graph);

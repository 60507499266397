/* English Language */
export default {
  email_address: "Email",
  password: "Password",
  login: "login",
  enter_email: "Email",
  powered_by: "powered by",
  send_a_link: "Send a link",
  register_email:
    "Enter your registered email. We will send a link to verify you.",
  contact_administrator_text:
    "Your myMueller user hasn’t been yet linked with the MilkSecure account. Please contact the administrator by sending an email.",
  home: "Home",
  page_not_found: "Oops, the page you are looking does not exist.",
  add_new_region: "add new region",
  search: "Search",
  add_new_regional_manager: "add new regional manager",
  data_not_available: "Data not available",
  actions: "actions",
  edit: "Edit",
  delete: "Delete",
  add: "add",
  regionId: "region ID",
  region_name: "region name",
  regional_managers: "regional manager",
  user_detail: "Profile and Preferences",
  change_password: "change password",
  log_out: "Log Out",
  region: "Region",
  phone_number: "phone number",
  email: "email",
  super_admin: "super admin",
  dealers: "dealers",
  independent_dairies: "independent dairies",
  add_new_dealer: "add new dealer",
  add_new_super_admin: "add new super admin",
  delete_record_msg:
    "Warning!! Deleting User's data will compromise all the information of the dairies and tanks installed in your premises. You will not be able to retrieve the information back. Are you sure you want to continue?",
  edit_super_admin: "edit super admin",
  edit_region: "edit region",
  region_added_successfully: "region added successfully.",
  something_went_wrong: "Some thing went wrong",
  region_updated_successfully: "region updated successfully.",
  region_deleted_successfully: "region deleted successfully.",
  super_admin_deleted_successfully: "super admin deleted successfully.",
  super_admin_added_successfully: "super admin added successfully.",
  super_admin_updated_successfully: "super admin updated successfully.",
  regional_manager_added_successfully: "regional manager added successfully.",
  regional_manager_deleted_successfully:
    "regional manager deleted successfully.",
  regional_manager_updated_successfully:
    "regional manager updated successfully.",
  edit_regional_manager: "edit regional manager",
  dealer_added_successfully: "dealer added successfully.",
  dealer_deleted_successfully: "dealer deleted successfully.",
  edit_dealer: "edit dealer",
  dealer_updated_successfully: "dealer updated successfully.",
  edit_independent_dairy: "edit independent dairy",
  independent_dairy_deleted_successfully:
    "independent dairy deleted successfully.",
  independent_dairy_updated_successfully:
    "independent dairy updated successfully.",
  producer_id: "producer ID",
  city: "city",
  state: "state",
  county: "County/District",
  address: "address",
  dairy_farm: "dairy farm",
  dairy_name: "dairy name",
  dairy_admin: "dairy owner",
  dairy_farms: "dairy farms",
  technicians: "technicians",
  add_new_dairy_farm: "add new dairy farm",
  technician_deleted_successfully: "technician deleted successfully.",
  add_new_technician: "add new technician",
  technician: "technician",
  edit_technician: "edit technician",
  technician_name: "technician name",
  assign_dairies: "assign dairies",
  technician_added_successfully: "technician added successfully.",
  dairy_added_successfully: "dairy added successfully.",
  dairy_updated_successfully: "dairy updated successfully.",
  dairy_deleted_successfully: "dairy deleted successfully.",
  technician_updated_successfully: "technician updated successfully.",
  old_password: "old Password",
  new_password: "new Password",
  re_enter_new_password: "re-enter new password",
  reset_password: "reset password",
  password_not_match: "New Password and Confirm Password are not matching.",
  changed_password_message: "password set successfully.",
  current_password_wrong: "Current password is wrong",
  password_reset_link_invalid: "password reset link invalid",
  required_field_error: "This field is required.",
  password_length_error: "Password must have 8 characters long",
  link_sent_successfully: "link sent successfully.",
  user_not_registered: "User is not registered with this email",
  region_id_required: "Region ID is required.",
  region_name_error: "Region Name is required.",
  name_error: "Name is required.",
  email_error: "Email is required.",
  region_error: "Region is required.",
  password_error: "Password is required.",
  dealer_name: "Dealer name is required.",
  dealer_email: "Dealer email is required.",
  producer_id_error: "Producer id is required.",
  dairy_name_error: "Dairy name is required.",
  dairy_address: "Dairy address is required.",
  dairy_city: "Dairy city is required.",
  dairy_state: "Dairy state is required.",
  technician_name_error: "Technician name is required.",
  technician_email: "Technician email is required.",
  login_screen_text: "Log in to your account",
  PMC_Login: "Login with",
  forgot_password: "Can't log in?",
  or: "OR",
  privacy_policy: "Privacy Policy",
  terms_and_conditions: "Terms and Conditions",
  data_policy: "Data Policy",
  end_user_license_agreement: "End-User License Agreement",
  contact_administrator: "Contact Administrator",
  back_to_login: "Back to login",
  are_you_sure: "Are you sure you want to continue?",
  add_new_tank: "Add New Tank",
  no_data: "No Data Available",
  tank_not_registered: "Tank not registered",
  the_dairy_owner_has_not_registered: "The dairy owner has not registered.",
  tank_mode: "Tank's Mode",
  last_agitated: "Last Agitated",
  live_data: "Live Data",
  re_register: "Re-Register",
  raw_register: "Raw Registers",
  register_values: "Parsed Registers",
  tank_name: "Tank Name",
  num_evcs: "No. of EVCs",
  tank_added: "Tank added successfully.",
  tank_updated: "Tank updated successfully.",
  tank_deleted: "Tank deleted successfully.",
  edit_dairy_farm: "Edit dairy farm",
  create: "create",
  update: "save",
  dealer: "dealer",
  regional_manager: "regional manager",
  super_admin: "super admin",
  independent_dairy: "independent dairy",
  send_invitation_link: "Account Activation Link Sent",
  user_has_been_not_registered: "User has not been registered yet",
  resend_link: "Resend link",
  sent: "sent",
  set_password: "set password",
  set_validation_message:
    "Please use password between 8 - 16 characters which should include at least 1 numeric character, 1 special character, one uppercase and one lowercase without any space.",
  password_regex_message: "Password is not valid",
  not_register: "Not registered",
  password_required: "Password is required.",
  password_min: "Password must contain at least 8 to 16 characters",
  enter_a_new_password: "Enter new password",
  confirm_new_password: "Confirm new password",
  confirm_password_required: "Confirm Password is required.",
  alert_history_error: "Error while fetching alert history",
  can_not_login: "Can’t log in?",
  new_password_required: "New Password is required.",
  old_password_required: "Old Password is required.",
  re_enter_new_password_required: "Re-enter new Password is required.",
  by_signing_up: "By signing up, you agree to our privacy policy linked below.",
  is_registered: "Registered",
  resend_email_link_expired:
    "Your link is expired please contact your administrator",
  back_to_login: "Back to login",
  details_updated_successfully: "User details updated successfully.",
  language_preferences: "Language Preferences",
  language_dropdown: "Language Dropdown",
  temperature_unit_preference: "Temperature Unit",
  pressure_unit: "Pressure Unit",
  volume_unit: "Volume Unit",
  alert_preference: "Alert Preference",
  unit_preference: "Unit Preferences",
  phone: "Phone",
  basic_details: "Basic Details",
  name: "Name",
  email: "Email",
  dairies: "Dairies",
  unable_to_connect_server:
    "Unable to connect the server. Please try again later.",
  loading: "Please wait, loading...",
  username_not_found: "Username not found.",
  tank_temperature: "Tank Temperature",
  tank_volume: "Tank Volume",
  pipeline_temperature: "Pipeline Temperature",
  add_new_tank: "Add New Tank",
  clear: "Clear",
  sms: "SMS",
  milk_pipeline_temperature: "Milk Pipeline Temp.",
  temperature_sensor_3: "Temperature Sensor 3",
  temperature_sensor_4: "Temperature Sensor 4",
  evc_temperature: "EVC Temperature",
  subcool_temperature: "Subcool Temperature",

  Cooling: "Cooling",
  BU_Cool: "BU Cool",
  Washing: "Washing",
  NULL: "NULL",
  INIT: "INIT",
  Power_Fail_Recovery: "Power Fail Recovery",
  Standby: "Standby",
  Sample_Agitate: "Sample Agitate",
  Editing_Parameters: "Editing Parameters",
  Diagnostic: "Diagnostic",
  Setup: "Setup",
  BU_Delay_Start: "BU Delay Start",
  AU_Bottom_Delay_Start: "AU Bottom Delay Start",
  AU_Upper_Delay_Start: "AU Upper Delay Start",
  AU_Cool: "AU Cool",
  BU_Delay_BU_Pulsed_Cool: "BU Delay BU Pulsed Cool",
  AU_Delay_BU_Pulsed_Cool: "AU Delay BU Pulsed Cool",
  FW: "FW",
  AC: "AC",
  SC: "SC",
  Rinse_1_WWB: "Rinse 1 (WWB)",
  Delay_WWB: "Delay (WWB)",
  Rinse_2_WWB: "Rinse 2 (WWB)",
  Detergent_Wash_WWB: "Detergent Wash (WWB)",
  Rinse_3_WWB: "Rinse 3 (WWB)",
  Acid_Rinse_WWB: "Acid Rinse (WWB)",
  Post_Rinse_WWB: "Post Rinse (WWB)",
  Complete_WWB: "Complete (WWB)",
  Rinse_1_ADB: "Rinse 1 (ADB)",
  Delay_ADB: "Delay (ADB)",
  Acid_Wash_ADB: "Acid Wash (ADB)",
  Rinse_2_ADB: "Rinse 2 (ADB)",
  Detergent_Wash_ADB: "Detergent Wash (ADB)",
  Rinse_3_ADB: "Rinse 3 (ADB)",
  Acid_Rinse_ADB: "Acid Rinse (ADB)",
  Post_Rinse_ADB: "Post Rinse (ADB)",
  Complete_ADB: "Complete (ADB)",
  SC_Complete_ADB: "SC Complete (ADB)",
  Rinse_1_MMB: "Rinse 1 (MMB)",
  Delay_MMB: "Delay (MMB)",
  Rinse_2_MMB: "Rinse 2 (MMB)",
  Detergent_Wash_MMB: "Detergent Wash (MMB)",
  Rinse_3_MMB: "Rinse 3 (MMB)",
  Acid_Rinse_MMB: "Acid Rinse (MMB)",
  Sanitize_Cycle_MMB: "Sanitize Cycle (MMB)",
  Pre_Rinse_MMB: "Pre Rinse (MMB)",
  Complete_MMB: "Complete (MMB)",
  SC_Complete_MMB: "SC Complete (MMB)",
  unit_preference: "Unit Preferences",
  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "Version",
  farm_users: "Farm Users",
  alerts: "Alerts",
  add_new_farm_users: "add new farm user",
  role: "Role",
  user_role: "Type of Farm User",
  farm_users_added_successfully: "farm user added successfully.",
  farm_users_deleted_successfully: "farm user deleted successfully.",
  user_create_different_role_error:
    "There's already a user created with a different role. Please contact the site admin to solve this.",
  farm_users_updated_successfully: "Farm user updated successfully.",
  close: "Close",
  assign: "Assign",
  email_already_exists: "User with the mentioned email ID already exist.",
  user_create_different_dairy_to_assign:
    "There's already a user created of that role. Do you want to add him/her to this dairy?",
  configure_alerts: "Configure Alerts",
  add_alert: "add alert",
  tank_name: "Tank Name",
  alert_deleted_successfully: "alert deleted successfully.",
  alert_not_found: "No customized alerts found.",
  sensor_name: "Sensor name",
  sensor_value: "Sensor value",
  duration: "Duration",
  tank_state: "tank state",
  condition: "Condition",
  delay: "Delay",
  minutes: "Minutes",
  sensor_value_error: "Sensor is required.",
  delay_value_error: "Delay is required.",
  duration_value_error: "Duration is required.",
  duplicate_email: "Duplicate Email id.",
  invalid_email: "Email format is wrong.",
  alert_added_successfully: "alert added successfully.",
  maximum_email: "You can not add more than 10 emails.",
  contact_number_error: "Contact Number is required.",
  duplicate_contact_number: "Duplicate Contact number",
  maximum_contact_number: "You can not add more than 10 Numbers.",
  contact_digit: "Contact number must be 10 digits.",
  config_alert:
    "Please enter atleast one phone number or an email to configure the alert.",
  update_form_button: "Update",
  live_alert_not_found: "There are no live alerts.",
  live_alerts: "Live Alerts",
  resolved_alerts: "Resolved Alerts",
  live_alert_history_error: "Error while fetching live alert history",
  loading_text: "Please wait, loading...",
  resolved_alert_not_found: "There are no resolved alerts",

  triggered: "Triggered",
  resolved: "Resolved",
  show_annotation: "Show Annotation",
  hide_annotation: "Hide Annotations",
  clear: "Clear",
  submit: "Submit",
  export_csv: "Export CSV",
  add_annotation_instruction:
    "Please hover over the graph and click where you want to add the annotation.",
  from_date_time: "From",
  to_date_time: "To",
  select_date: "Please select a date.",
  select_from_date: "Please select From date.",
  select_to_date: "Please select To date.",
  date_not_same: "Date can not be same.",
  to_date_greater_than_today: "To date can not be greater than today.",
  from_date_greater_than_today: "From date can not be greater than To date.",
  select_date_range: "Please select a date range within 31 days.",
  tank_data_not_available: "Tank device has no data between these dates.",
  download_failed: "Download failed.",
  file_downloaded: "Your file is being downloaded.",
  select_date_time: "Select date and time",
  regional_managers_error: "Regional Manager is required.",
  independent_dealers: "Independent Dealers",
  cancel_re_register: "Cancel Re-Register",
  re_registers_successfully: "Re-Register successfully.",
  cancel_registers_successfully: "Cancel Re-register Successfully.",
  evc_error: "EVC is required.",
  done: "Done",
  cancel: "Cancel",
  description: "Description",
  timeline: "Timeline",
  add_annotation: "Add Annotation",
  system_alert: "System Alert",
  manual_notes: "Manual Notes",
  delete_annotation_msg: "Are you sure want to delete this annotation?",
  manual_annotation_deleted_successfully:
    "manual annotation deleted successfully.",
  profile_error_message: "Failed to update profile, please try again later.",
  lsp: "Low Side Pressure",
  sst: "Saturated Suction Temperature",
  hsp: "High Side Pressure",
  sct: "Condensing Temperature",
  no_dairy_farm_data_available: "There are no such data for this dairy farms.",
  add_custom_alert: "Add custom alerts",
  farm_user: "Farm User",
  time_zone: "Timezone",
  tank_drag_drop: "Tank drag and drop successfully",
  calibration_charts: "Calibration Charts",
  add_new_chart: "Add new chart",
  chart_name: "Chart Name",
  type: "Type",
  manufacturer: "Manufacturer",
  calibration_chart: "Calibration Chart",
  create_myMueller_chart: "Create MyMueller Chart",
  horizontal_inside_diameter: "Tank Horizontal Inside Diameter",
  vertical_inside_diameter: "Tank Vertical Inside Diameter",
  shell_length: "Shell Length (Seam to Seam)",
  shell_material_thickness: "Shell Material Thickness",
  tank_pitch_foot: "Tank Pitch Per Foot (Inches) ",
  head_material_thickness: "Head Material Thickness",
  head_inside_knuckle: "Head Inside Knuckle Radius",
  height_increment_value: "The Height Increment Value",
  right_head_material_thickness: "Right Head Material Thickness",
  right_head_inside_knuckle: "Right Head Inside Knuckle Radius",
  create_chart: "Create Chart",
  transducer_limit: "Transducer Limit",
  calibration_deleted_successfully: "Calibration Chart Deleted Successfully.",
  chart_name_required: "Chart Name is required.",
  tank_type_required: "Type is required.",
  manufacture_required: "Manufacture is required.",
  transducer_limit_required: "Transducer Limit is required.",
  transducer_limit_invalid: "Invalid Transducer Limit.",
  tank_detail: "Tank Detail",
  calibration: "Calibration",
  create_new_with: "Create new with",
  tank_height: "Tank Height",
  inch: "inch",
  gal_usa: "gal USA",
  volume_increment: "Incremental Volume",
  accumulated_volume: "Total Volume",
  horizontal_inside_diameter_required:
    "Tank Horizontal Inside Diameter is required.",
  vertical_inside_diameter_required:
    "Tank Vertical Inside Diameter is required.",
  shell_length_required: "Shell Length is required.",
  shell_material_thickness_required: "Shell Material Thickness is required.",
  tank_pitch_foot_required: "Tank Pitch Per Foot (Inches) is required.",
  head_material_thickness_required: "Head Material Thickness is required.",
  head_inside_knuckle_required: "Head Inside Knuckle Radius is required.",
  height_increment_value_required: "The Height Increment Value is required.",
  right_head_material_thickness_required:
    "Right Head Material Thickness is required.",
  right_head_inside_knuckle_required:
    "Right Head Inside Knuckle Radius is required.",
  drag_drop_file: "Drag and Drop your file here or",
  just_click_browse_file: "just click for browse files",
  chart_added_successfully: "Chart Added successfully.",
  invalid_excel: "Uploaded wrong file format",
  calibration_chart_required: "Calibration Chart is required.",
  incorrect_file_format: "Incorrect file format",
  correct_file_format:
    "You have uploaded wrong format file, file should be in CSV format. Please remove current file and upload new with correct format.",
  special_instruction: "Special Instruction",
  accumulate_height_csv_text: "AccumulatedHeight",
  accumulate_volume_csv_text: "AccumulatedVolume",
  volume_increment_csv_text: "VolumeIncrement",
  sample_calibration_chart: "Download Calibration Chart Template",
  dairy_admin_exists: "Dairy admin is already exits with this dairy.",
  all_tanks: "All Tanks",
  producerId_max_length_error: "Producer ID should not exceed 50 characters",
  producerId_alphanumeric_error: "Only alphanumeric characters allowed",
  invalid_email_error: 'Enter a valid email',
  Agitating: 'Agitating',
  AGITATE: "AGITATE",
  COOL: "COOL",
  DIAGNOSTIC: "DIAGNOSTIC",
  SETTINGS: "SETTINGS",
  STANDBY: "STANDBY",
  WASH: "WASH",
  alert: "Alert",
  tank: "Tank",
  tanks: "Tanks",
  no_manufacturer: "No Manufacturer",
  mueller: "Mueller",
  non_mueller: "Non-Mueller",
  no_type: "No Type",
  horizontal: "Horizontal",
  vertical: "Vertical",
  dairy_owner: "Dairy Owner",
  dairy_operator: "Dairy Operator",
  milk_hauler: "Milk Hauler",
  sanitarian: "Sanitarian",
  HSP: "HSP",
  LSP: "LSP",
  valve_position: "Valve Position",
  less_than: "Less Than",
  greater_than: "Greater Than",
  cool: "Cool",
  wash: "Wash",
  standby: "Standby",
  BOTTOM_UNIT_COOL: "BOTTOM UNIT COOL",
  ALL_UNIT_COOL: "ALL UNIT COOL",
  BU_DELAY_BU_PULSE_COOL: "BU DELAY BU PULSE COOL",
  AU_DELAY_BU_PULSED_COOL: "AU DELAY BU PULSED COOL",
  WWB_FULL_WASH_RINSE_1: "WWB FULL WASH RINSE 1",
  WWB_FULL_WASH_DELAY: "WWB FULL WASH DELAY",
  WWB_FULL_WASH_RINSE_2: "WWB FULL WASH RINSE 2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWB FULL WASH DETERGENT WASH",
  WWB_FULL_WASH_RINSE_3: "WWB FULL WASH RINSE 3",
  WWB_FULL_WASH_ACID_RINSE: "WWB FULL WASH ACID RINSE",
  WWB_FULL_WASH_RINSE_4: "WWB FULL WASH RINSE 4",
  WWB_SANITIZE_CYCLE: "WWB SANITIZE CYCLE",
  WWB_ACID_CYCLE_PRE_RINSE: "WWB ACID CYCLE PRE RINSE",
  WWB_ACID_CYCLE_ACID_RINSE: "WWB ACID CYCLE ACID RINSE",
  WWB_ACID_CYCLE_POST_RINSE: "WWB ACID CYCLE POST RINSE",
  WWB_FULL_WASH_COMPLETE: "WWB FULL WASH COMPLETE",
  WWB_ACID_CYCLE_COMPLETE: "WWB ACID CYCLE COMPLETE",
  WWB_SANITIZE_CYCLE_COMPLETE: "WWB SANITIZE CYCLE COMPLETE",
  ADB_FULL_WASH_RINSE_1: "ADB FULL WASH RINSE 1",
  ADB_FULL_WASH_DELAY: "ADB FULL WASH DELAY",
  ADB_FULL_WASH_ACID_WASH: "ADB FULL WASH ACID WASH",
  ADB_FULL_WASH_RINSE_2: "ADB FULL WASH RINSE 2",
  ADB_FULL_WASH_DETERGENT_WASH: "ADB FULL WASH DETERGENT WASH",
  ADB_FULL_WASH_RINSE_3: "ADB FULL WASH RINSE 3",
  ADB_FULL_WASH_ACID_RINSE: "ADB FULL WASH ACID RINSE",
  ADB_FULL_WASH_RINSE_4: "ADB FULL WASH RINSE 4",
  ADB_SANITIZE_CYCLE: "ADB SANITIZE CYCLE",
  ADB_ACID_CYCLE_PRE_RINSE: "ADB ACID CYCLE PRE RINSE",
  ADB_ACID_CYCLE_ACID_RINSE: "ADB ACID CYCLE ACID RINSE",
  ADB_ACID_CYCLE_POST_RINSE: "ADB ACID CYCLE POST RINSE",
  ADB_FULL_WASH_COMPLETE: "ADB FULL WASH COMPLETE",
  ADB_ACID_CYCLE_COMPLETE: "ADB ACID CYCLE COMPLETE",
  ADB_SANITIZE_CYCLE_COMPLETE: "ADB SANITIZE CYCLE COMPLETE",
  MMB_FULL_WASH_RINSE_1: "MMB FULL WASH RINSE 1",
  MMB_FULL_WASH_DELAY: "MMB FULL WASH DELAY",
  MMB_FULL_WASH_RINSE_2: "MMB FULL WASH RINSE 2",
  MMB_FULL_WASH_DETERGENT_WASH: "MMB FULL WASH DETERGENT WASH",
  MMB_FULL_WASH_RINSE_3: "MMB FULL WASH RINSE 3",
  MMB_FULL_WASH_ACID_RINSE: "MMB FULL WASH ACID RINSE",
  MMB_SANITIZE_CYCLE: "MMB SANITIZE CYCLE",
  MMB_ACID_CYCLE_PRE_RINSE: "MMB ACID CYCLE PRE RINSE",
  MMB_ACID_CYCLE_ACID_RINSE: "MMB ACID CYCLE ACID RINSE",
  MMB_FULL_WASH_COMPLETE: "MMB FULL WASH COMPLETE",
  MMB_ACID_CYCLE_COMPLETE: "MMB ACID CYCLE COMPLETE",
  MMB_SANITIZE_CYCLE_COMPLETE: "MMB SANITIZE CYCLE COMPLETE",
  EVC_INPUT_REG_TEMP_1: "EVC Temperature Sensor 1",
  EVC_INPUT_REG_TEMP_2: "EVC Temperature Sensor 2",
  EVC_INPUT_REG_TEMP_3: "EVC Temperature Sensor 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "Subcool Temperature",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "HSP",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "LSP",
  EVC_INPUT_REG_HI_SAT_TEMP: "High Saturation Temperature",
  EVC_INPUT_REG_LO_SAT_TEMP: "Low Saturation Temperature",
  EVC_INPUT_REG_FAN_OUTPUT: "Fan Speed",
  HPF_INPUT_REG_TEMP1: "Tank Temperature",
  HPF_INPUT_REG_TEMP2: "Milk Pipeline Temperature",
  HPF_INPUT_REG_TEMP3: "Temperature 3",
  HPF_INPUT_REG_TEMP4: "Temperature 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "Tank Volume",
};

import React, { memo } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Fan from "../../assets/images/ic_fan-dashboard-new.png";
import moment from "moment-timezone";
import styled from "styled-components";
import bottomUnitIcon from "../../assets/images/BU.svg";
import { useTranslation } from "react-i18next";

const TankStatusImg = styled(Image)`
  align-self: center;
  margin-right: 10px;
  height: 34px;
`;

const LiveDataSideBar = memo(
  ({
    tankStatusObj,
    agitatedTime,
    liveDataHelper,
    deviceType,
    secondsToHourMin,
    currentTankData,
    dropdownTime,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <Row>
          <Col lg={12} className="font-weight-bold mb-3">
            {t("tank_mode")}
          </Col>
          <Col lg={12} className="mb-3 d-flex align-items-center">
            {tankStatusObj && tankStatusObj.image ? (
              <TankStatusImg src={tankStatusObj.image} fluid />
            ) : null}

            <div>
              <p
                className="font-weight-bold m-0"
                style={{
                  color: tankStatusObj ? tankStatusObj.color : "black",
                }}
              >
                {tankStatusObj
                  ? tankStatusObj.textOne
                  : currentTankData !== undefined
                  ? "--"
                  : "--"}
              </p>
              <span>
                {tankStatusObj?.mainTimer}
                {(tankStatusObj?.timerVal !== undefined &&
                  tankStatusObj?.timerVal !== "0") ||
                tankStatusObj?.timerVal !== "0" ? (
                  <span className="timerTag" id="xyz">
                    {secondsToHourMin(tankStatusObj?.timerVal)}
                  </span>
                ) : null}
              </span>
            </div>
          </Col>

          <Col lg={12} className="mb-3 d-flex align-items-center">
            {tankStatusObj && tankStatusObj.textTwo && (
              <>
                <Image src={bottomUnitIcon} fluid />
                <div className="ml-3 text-truncate">
                  {tankStatusObj.textTwo}
                  {tankStatusObj.textThree
                    ? ` - ${tankStatusObj.textThree}`
                    : ""}
                </div>
              </>
            )}
          </Col>
        </Row>
        <Row>
          {agitatedTime ? (
            <Col lg={12} className="font-weight-bold  mb-3">
              {t("last_agitated")}
            </Col>
          ) : (
            ""
          )}
          <Col lg={12} className="pr-0 d-flex align-items-center">
            <div>
              <Image
                src={Fan}
                fluid
                className={
                  tankStatusObj?.hpfFan === "1" ? "fan-rotate" : "hpf-fan mr-2"
                }
              />
            </div>
            <div className="">
              <p className="m-0">
                {agitatedTime && tankStatusObj?.hpfFan === "0"
                  ? dropdownTime === undefined
                    ? moment(agitatedTime).format("D MMM, hh:mm A")
                    : moment
                        .tz(new Date(agitatedTime), dropdownTime)
                        .format("D MMM, hh:mm A")
                  : ""}
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={12} className="font-weight-bold mb-3">
            {t("live_data")}
          </Col>
          {liveDataHelper[deviceType].map((data, index) => {
            return (
              <Col lg={12} key={index} className="mb-4">
                <div className="d-flex align-items-center">
                  <div style={{ width: "33px" }}>
                    <Image
                      src={data.icon}
                      alt=""
                      fluid
                      style={{ marginRight: "12px" }}
                    />
                  </div>
                  <div
                    className={
                      data.name === t("subcool_temperature") ? "ml-1" : ""
                    }
                  >
                    <p className="m-0">{data.name}:</p>
                    <span className="font-weight-bold">
                      {data.value} {data.unit}
                    </span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </>
    );
  }
);

export default LiveDataSideBar;

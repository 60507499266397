/**
 * @function getTempAsPerSelectedUnitSystem converts the incoming temperature value based on the logged-in user's
 * preferred unit that is stored in the local storage
 * @param {number} temperature temperature value.
 * @returns {object} contains converted temperature value and unit.
 */
export const getTempAsPerSelectedUnitSystem = (temperature, isSubcool) => {
  // if the incoming temperature value is not a number return --
  if (typeof temperature !== "number" || Number.isNaN(temperature)) {
    return { temperature: "--", tempUnit: "" };
  }

  // get user's unit preference from the local storage
  const { temperatureUnit } = JSON.parse(localStorage.getItem("user"));

  switch (temperatureUnit) {
    case "C":
      return {
        temperature: isSubcool ? (temperature * (5 / 9)).toFixed(1) : (((temperature - 32) * 5) / 9).toFixed(1),
        tempUnit: "\u00b0C",
      };
    case "F":
      return { temperature: Math.round(temperature), tempUnit: "\u00b0F" };
    case "K":
      return {
        temperature: (((temperature - 32) * 5) / 9 + 273.15).toFixed(1),
        tempUnit: "K",
      };
    default:
      return { temperature: Math.round(temperature), tempUnit: "\u00b0F" };
  }
};

/**
 * @function getVolumeAsPerSelectedUnitSystem converts the incoming volume value based on the logged-in user's
 * preferred unit that is stored in the local storage
 * @param {number} volume volume value.
 * @returns {object} contains converted volume value and unit.
 */
export const getVolumeAsPerSelectedUnitSystem = (volume) => {
  // if the incoming volume value is not a number return --
  if (typeof volume !== "number" || Number.isNaN(volume)) {
    return { volume: "--", volumeUnit: "" };
  }

  // get user's unit preference from the local storage
  const { volumeUnit } = JSON.parse(localStorage.getItem("user"));

  switch (volumeUnit) {
    case "gal":
      return { volume, volumeUnit: "gal" };
    case "L":
      return { volume: Math.round(volume * 3.78), volumeUnit: "L" };
    case "lb":
      return { volume: Math.round(volume * 8.6), volumeUnit: "lb" };
    case "kg":
      return { volume: Math.round(volume * 8.6 * 0.45), volumeUnit: "kg" };
    default:
      return { volume, volumeUnit: "gal" };
  }
};

/**
 * @function getPressureAsPerSelectedUnitSystem converts the incoming temperature value based on the logged-in user's
 * preferred unit that is stored in the local storage
 * @param {number} temperature temperature value.
 * @returns {object} contains converted temperature value and unit.
 */
export const getPressureAsPerSelectedUnitSystem = (pressure) => {
  // if the incoming temperature value is not a number return --

  if (typeof pressure !== "number" || Number.isNaN(pressure)) {
    return { pressure: "--", pressureUnit: "" };
  }

  // get user's unit preference from the local storage
  const { pressureUnit } = JSON.parse(localStorage.getItem("user"));

  switch (pressureUnit) {
    case "psig":
      return { pressure: pressure.toFixed(2), pressureUnit: "psig" };
    case "bar":
      return {
        pressure: (pressure * 0.069).toFixed(2),
        pressureUnit: "bar",
      };
    case "MPa":
      return {
        pressure: (pressure * 0.0069).toFixed(2),
        pressureUnit: "MPa",
      };
    default:
      return { pressure, pressureUnit: "psig" };
  }
};

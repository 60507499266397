import React, { memo, useState } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { postMyMuellerChart } from "../../actions/actions";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import axios from "axios";

const CreateMyMuellerChartForm = memo(
  ({ showModel, closeModel, updatedMuellerData }) => {
    const { t } = useTranslation();
    const schema = yup.object().shape({
      TankHorizontalDiameter: yup
        .number()
        .positive()
        .typeError(t("horizontal_inside_diameter_required")),
      TankVerticalDiameter: yup
        .number()
        .positive()
        .typeError(t("vertical_inside_diameter_required")),
      ShellLength: yup.number().typeError(t("shell_length_required")),
      ShellMaterialThickness: yup
        .number()
        .positive()
        .typeError(t("shell_material_thickness_required")),
      ShellPitchPerFoot: yup.number().typeError(t("tank_pitch_foot_required")),
      LeftHeadMaterialThickness: yup
        .number()
        .positive()
        .typeError(t("head_material_thickness_required")),
      LeftHeadInsideKnuckleRadius: yup
        .number()
        .positive()
        .typeError(t("head_inside_knuckle_required")),
      RightHeadMaterialThickness: yup
        .number()
        .positive()
        .typeError(t("height_increment_value_required")),
      RightHeadInsideKnuckleRadius: yup
        .number()
        .positive()
        .typeError(t("right_head_material_thickness_required")),
      IncrementalHeightValue: yup
        .number()
        .positive()
        .typeError(t("right_head_inside_knuckle_required")),
    });

    const { register, handleSubmit, errors } = useForm({
      mode: "onSubmit",
      validationSchema: schema,
    });

    const postMyMuellerChartData = (data) => {
      const api = "https://connect.paulmueller.com/milk-cooler-volume";
      const options = {
        headers: { "content-type": "application/json" },
      };
      axios
        .post(api, options, data)
        .then((result) => {
          if (result.status === 200) {
            console.log(result.data);
            closeModel();
            updatedMuellerData(result.data, "add");
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("dealer_added_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast.dark(
            <Toaster icon="error" message={t("something_went_wrong")} />
          );
        });
    };
    return (
      <Modal
        show={showModel}
        onHide={closeModel}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(postMyMuellerChartData)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {t("create_myMueller_chart")}{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("horizontal_inside_diameter")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="TankHorizontalDiameter"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.TankHorizontalDiameter}
                  />
                  {errors.TankHorizontalDiameter && (
                    <Form.Control.Feedback type="invalid">
                      {errors.TankHorizontalDiameter.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("vertical_inside_diameter")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="TankVerticalDiameter"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.TankVerticalDiameter}
                  />
                  {errors.TankVerticalDiameter && (
                    <Form.Control.Feedback type="invalid">
                      {errors.TankVerticalDiameter.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("shell_length")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="ShellLength"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.ShellLength}
                  />
                  {errors.ShellLength && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ShellLength.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("shell_material_thickness")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="ShellMaterialThickness"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.ShellMaterialThickness}
                  />
                  {errors.ShellMaterialThickness && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ShellMaterialThickness.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("tank_pitch_foot")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="ShellPitchPerFoot"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.ShellPitchPerFoot}
                  />
                  {errors.ShellPitchPerFoot && (
                    <Form.Control.Feedback type="invalid">
                      {errors.ShellPitchPerFoot.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("head_material_thickness")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="LeftHeadMaterialThickness"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.LeftHeadMaterialThickness}
                  />
                  {errors.LeftHeadMaterialThickness && (
                    <Form.Control.Feedback type="invalid">
                      {errors.LeftHeadMaterialThickness.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("head_inside_knuckle")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="LeftHeadInsideKnuckleRadius"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.LeftHeadInsideKnuckleRadius}
                  />
                  {errors.LeftHeadInsideKnuckleRadius && (
                    <Form.Control.Feedback type="invalid">
                      {errors.LeftHeadInsideKnuckleRadius.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("right_head_inside_knuckle")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="RightHeadMaterialThickness"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.RightHeadMaterialThickness}
                  />
                  {errors.RightHeadMaterialThickness && (
                    <Form.Control.Feedback type="invalid">
                      {errors.RightHeadMaterialThickness.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("right_head_material_thickness")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="RightHeadInsideKnuckleRadius"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.RightHeadInsideKnuckleRadius}
                  />
                  {errors.RightHeadInsideKnuckleRadius && (
                    <Form.Control.Feedback type="invalid">
                      {errors.RightHeadInsideKnuckleRadius.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="formBasicName">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("height_increment_value")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="IncrementalHeightValue"
                    size="lg"
                    type="text"
                    ref={register()}
                    isInvalid={!!errors.IncrementalHeightValue}
                  />
                  {errors.IncrementalHeightValue && (
                    <Form.Control.Feedback type="invalid">
                      {errors.IncrementalHeightValue.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
            >
              {t("create_chart")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

export default CreateMyMuellerChartForm;

import React from "react";
import moment from "moment";
import i18n from '../i18n';

import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
} from "../helpers";

const t = i18n.t.bind(i18n);

/** Password regex */
export const PASSWORD_REGEXP = new RegExp(
  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=])(?=\\S+$).{8,16}$"
);

/** Global farmUserRole static array */
export const farmUsersRole = [
  {
    label: i18n.t("dairy_owner"),
    value: "DairyAdmin",
  },
  {
    label: i18n.t("dairy_operator"),
    value: "DairyOperator",
  },
  {
    label: i18n.t("milk_hauler"),
    value: "MilkHauler",
  },
  {
    label: i18n.t("sanitarian"),
    value: "Sanitarian",
  },
];

/** global tank state array */
export const tankStateArr = {
  cool: t("cool"),
  wash: t("wash"),
  standby: t("standby"),
  2003: t("BOTTOM_UNIT_COOL"),
  2004: t("ALL_UNIT_COOL"),
  2005: t("BU_DELAY_BU_PULSE_COOL"),
  2006: t("AU_DELAY_BU_PULSED_COOL"),
  3000: t("WWB_FULL_WASH_RINSE_1"),
  3001: t("WWB_FULL_WASH_DELAY"),
  3002: t("WWB_FULL_WASH_RINSE_2"),
  3003: t("WWB_FULL_WASH_DETERGENT_WASH"),
  3004: t("WWB_FULL_WASH_RINSE_3"),
  3005: t("WWB_FULL_WASH_ACID_RINSE"),
  3006: t("WWB_FULL_WASH_RINSE_4"),
  3007: t("WWB_SANITIZE_CYCLE"),
  3008: t("WWB_ACID_CYCLE_PRE_RINSE"),
  3009: t("WWB_ACID_CYCLE_ACID_RINSE"),
  3010: t("WWB_ACID_CYCLE_POST_RINSE"),
  3011: t("WWB_FULL_WASH_COMPLETE"),
  3012: t("WWB_ACID_CYCLE_COMPLETE"),
  3013: t("WWB_SANITIZE_CYCLE_COMPLETE"),
  3100: t("ADB_FULL_WASH_RINSE_1"),
  3101: t("ADB_FULL_WASH_DELAY"),
  3102: t("ADB_FULL_WASH_ACID_WASH"),
  3103: t("ADB_FULL_WASH_RINSE_2"),
  3104: t("ADB_FULL_WASH_DETERGENT_WASH"),
  3105: t("ADB_FULL_WASH_RINSE_3"),
  3106: t("ADB_FULL_WASH_ACID_RINSE"),
  3107: t("ADB_FULL_WASH_RINSE_4"),
  3108: t("ADB_SANITIZE_CYCLE"),
  3109: t("ADB_ACID_CYCLE_PRE_RINSE"),
  3110: t("ADB_ACID_CYCLE_ACID_RINSE"),
  3111: t("ADB_ACID_CYCLE_POST_RINSE"),
  3112: t("ADB_FULL_WASH_COMPLETE"),
  3113: t("ADB_ACID_CYCLE_COMPLETE"),
  3114: t("ADB_SANITIZE_CYCLE_COMPLETE"),
  3200: t("MMB_FULL_WASH_RINSE_1"),
  3201: t("MMB_FULL_WASH_DELAY"),
  3202: t("MMB_FULL_WASH_RINSE_2"),
  3203: t("MMB_FULL_WASH_DETERGENT_WASH"),
  3204: t("MMB_FULL_WASH_RINSE_3"),
  3205: t("MMB_FULL_WASH_ACID_RINSE"),
  3206: t("MMB_SANITIZE_CYCLE"),
  3207: t("MMB_ACID_CYCLE_PRE_RINSE"),
  3208: t("MMB_ACID_CYCLE_ACID_RINSE"),
  3209: t("MMB_FULL_WASH_COMPLETE"),
  3210: t("MMB_ACID_CYCLE_COMPLETE"),
  3211: t("MMB_SANITIZE_CYCLE_COMPLETE"),
};

/** Global condition array */
export const conditionArr = { lt: i18n.t("less_than"), gt: i18n.t("greater_than") };

export const getAlertValueBasedOnUnitPreference = (
  value,
  unit,
  whatToReturn
) => {
  switch (unit) {
    case "°F":
      ({ tempUnit: unit, temperature: value } = getTempAsPerSelectedUnitSystem(
        Number(value)
      ));
      break;
    case "psig":
      ({
        pressureUnit: unit,
        pressure: value,
      } = getPressureAsPerSelectedUnitSystem(Number(value)));
      break;
    case "gal":
      ({ volumeUnit: unit, volume: value } = getVolumeAsPerSelectedUnitSystem(
        Number(value)
      ));
      break;
    default:
      break;
  }

  switch (whatToReturn) {
    case "value":
      return value;
    case "unit":
      return unit;
    case "valueAndUnit":
    default:
      return value + " " + unit;
  }
};

export const registerNames = {
  "Bottom Unit #1": "32",
  "Bottom Unit #2": "33",
  "Side Unit #3": "34",
  "Side Unit #4": "35",
};

/** HPF and EVC Sensors */
export const sensors = {
  HPF: [
    "HPF_INPUT_REG_TEMP1",
    "HPF_INPUT_REG_TEMP2",
    "HPF_INPUT_REG_TEMP3",
    "HPF_INPUT_REG_TEMP4",
    "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
    "HPF_INPUT_REG_MAIN_STATE",
  ],
  EVC: [
    [
      "EVC_INPUT_REG_LOW_SIDE_PRESSURE",
      "EVC_INPUT_REG_LO_SAT_TEMP",
      "EVC_INPUT_REG_HIGH_SIDE_PRESSURE",
      "EVC_INPUT_REG_HI_SAT_TEMP",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
    ],
    [
      "EVC_INPUT_REG_SUBCOOL_TEMP",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
    ],
    [
      "EVC_INPUT_REG_TEMP_1",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
    ],
  ],
};

/** Display data ranges function */
export const ranges = {
  Yesterday: [new Date().setDate(new Date().getDate() - 1), new Date()],
  "Last 7 Days": [new Date().setDate(new Date().getDate() - 7), new Date()],
  "Last 30 Days": [new Date().setDate(new Date().getDate() - 30), new Date()],
  "Last Month": [
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  ],
  "This Month": [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
  ],
};

/** Display timer below unit */
export const secondsToHourMin = (seconds) => {
  const duration = moment.duration(parseInt(seconds, 10), "seconds");
  let formatted;
  if (duration._isValid === true) {
    formatted = (
      <>
        {duration.get("days") !== 0 ? (
          <>
            {duration.get("days")}d<span className="blink">:</span>
          </>
        ) : (
            ""
          )}
        {(duration.get("hours") > 9 ? "" : "0") + duration.get("hours")}h
        <span className="blink">:</span>
        {(duration.get("minutes") > 9 ? "" : "0") + duration.get("minutes")}m
        <span className="blink">:</span>
        {(duration.get("seconds") > 9 ? "" : "0") + duration.get("seconds")}s
      </>
    );
    return formatted;
  }
  return "";
};

/** Display tank type */
export const TankTypes = [
  {
    label: i18n.t("horizontal"),
    value: "HORIZONTAL",
  },
  {
    label: i18n.t("vertical"),
    value: "VERTICAL",
  },
];

/** Display manufacturer type */
export const TankManufactureTypes = [
  {
    label: i18n.t("mueller"),
    value: "MUELLER",
  },
  {
    label: i18n.t("non_mueller"),
    value: "NON-MUELLER",
  },
];

export const getFlexWidth = (numDevices) => {
  if (numDevices === 0) {
    return 0;
  }
  if (numDevices === 1) {
    return 9;
  }
  if (numDevices <= 2) {
    return 14;
  }
  if (numDevices === 3) {
    return 24;
  }
  if (numDevices === 4) {
    return 30;
  }
  if (numDevices === 5) {
    return 30;
  }
  return 30;
};

import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Graph from "../../components/Graph";
import GraphLoader from "../../components/GraphLoader";
import NoDataAvailable from "../../components/NoDataAvailable";
const HPF = memo(
  ({
    deviceType,
    graphLoader,
    hpfGraphData,
    washData,
    liveAlerts,
    sensors,
    showAnnotation,
    alerts,
    manualAnnotation,
    getAlerts,
    configId,
    dropdownTime,
    isResponsiveMode,
  }) => {
    const { t } = useTranslation();
    return (
      <>
        {deviceType === "HPF" && liveAlerts === false ? (
          graphLoader ? (
            <GraphLoader />
          ) : hpfGraphData === "" ? (
            <center>
              <NoDataAvailable title={t("no_data")} />
            </center>
          ) : (
            <Row>
              <Col lg={12}>
                <Graph
                  data={hpfGraphData}
                  washData={washData}
                  sensors={sensors.HPF}
                  graphId="hpf"
                  showAnnotation={showAnnotation}
                  alerts={alerts}
                  manualAnnotation={manualAnnotation}
                  getAlerts={getAlerts}
                  configId={configId}
                  dropdownTime={dropdownTime}
                  isResponsiveMode={isResponsiveMode}
                />
              </Col>
            </Row>
          )
        ) : null}
      </>
    );
  }
);

export default HPF;

import React, { memo, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Card from "../../components/Card";
import { useTranslation } from "react-i18next";
import {
  deleteRegion,
  getRegions,
  getIndependentDealersList,
} from "../../actions/actions";
import Search from "../../components/Search";
import PromptModel from "components/PromptModel/PromptModel";
import EditRegionForm from "forms/Region/EditRegionForm";
import AddRegionForm from "forms/Region/AddRegionForm";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import { useApiContext } from "../../context/apiContext";
import { useBreadcrumbContext } from "../../context/breadcrumbContext";
import CardViewLoader from "../../components/CardViewLoader";
import { useHistory } from "react-router-dom";

const RegionTab = memo(({ setDealers }) => {
  const { t } = useTranslation();
  const apiRegion = useApiContext();
  const { setBreadcrumbRegion } = useBreadcrumbContext();
  const history = useHistory();
  const [show, setShow] = useState(false);
  /* set searchResult data with useState */
  const [region, setRegion] = useState([]);
  // const [regionalManagers, setRegionalManagers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [display, setDisplay] = useState(false);
  const [user, setUser] = useState(null);
  const [regionData, setRegionData] = useState([]);
  const [cardViewLoader, setCardViewLoader] = useState(false);
  /* set searchTerm data with useState */
  const [searchTerm, setSearchTerm] = useState("");


  /** Display Region Listing */
  const getRegionsListing = () => {
    setCardViewLoader(true);
    getRegions().then((response) => {
      setCardViewLoader(false);
      setRegion(response.data);
      apiRegion.setRegionApi(response.data);
    });
  };

  /** Delete Region api call */
  const deleteRecord = (id) => {
    deleteRegion(id)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          getIndependentDealersList().then((data) => setDealers(data.data));
          setSelectedId(null);
          setOpen(false);
          toast.dark(
            <Toaster
              className="text-capitalize"
              icon="notify"
              message={t("region_deleted_successfully")}
            />,
            { autoClose: 3000, hideProgressBar: true }
          );
          getRegionsListing();
        }
      })
      .catch((err) => {
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response?.message ?? t("something_went_wrong")}
          />
        );
      });
  };

  useEffect(() => {
    getRegionsListing();
  }, []);

  /** Added and update data after update state data */
  const updatedList = (item, action) => {
    if (action === "add") {
      setRegion([item, ...region]);
      apiRegion.setRegionApi([item, ...region]);
    }
    if (action === "edit") {
      setRegion(region.map((i) => (i.id === item.id ? item : i)));
      apiRegion.setRegionApi(region.map((i) => (i.id === item.id ? item : i)));
    }
  };

  const results = region.filter((card) => {
    return card.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <>
      <Row>
        <Col xs={12} lg={3} md={8} sm={7} className="mb-4">
          <Search
            placeholder={t("search")}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_new_region")}
          </Button>
        </Col>
      </Row>

      {cardViewLoader ? (
        <CardViewLoader />
      ) : (
          results.length < 1 ? (
            <div className="text-center lead">{t("data_not_available")}</div>
          ) : (
              <Row>{
                results.map((item, i) => {
                  return (
                    <Col lg={3} md={4} className="mb-4" key={i}>
                      <Card
                        key={"regions" + item.id}
                        title={item.name === null ? "" : item.name}
                        subtitle={`ID: ${item.regionId}`}
                        rightIcon={false}
                        onClick={() => {
                          history.push(`regions/${item.id}/dealers`);
                          setBreadcrumbRegion(item.name);
                        }}
                        deleteHandler={() => {
                          setSelectedId(item.id);
                          setOpen(true);
                        }}
                        editHandler={() => {
                          setDisplay(true);
                          setUser(item.id);
                          setRegionData(item);
                        }}
                      >
                        {item.regionManager === null ? "" : item.regionManager.name}
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            )
        )}

      {show && (
        <AddRegionForm
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updatedList}
        />
      )}

      {display && (
        <EditRegionForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          region={user}
          regionData={regionData}
          updateList={updatedList}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteRecord(selectId)}
      />
    </>
  );
});

export default RegionTab;
